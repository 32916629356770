import { makeObservable, observable } from "mobx";
import Account from "../components/models/Account";
import Store from "./store";
import { doFetch } from "../utils";

const URLS = {
  LOGIN: "/api/auth/login",
  LOGOUT: "/api/auth/logout",
};

class AuthStore extends Store {
  loading: boolean;
  account: Account;

  constructor() {
    super();
    this.loading = false;
    this.account = new Account();

    makeObservable(this, {
      loading: observable,
      account: observable,
    });
  }

  login = async (): Promise<{ status: number; data: any }> => {
    return doFetch(URLS.LOGIN, "POST", {
      email: this.account.email,
      password: this.account.password,
    });
  };

  logout = async (): Promise<{ status: number; data: any }> => {
    return doFetch(URLS.LOGOUT, "POST");
  };
}

export default new AuthStore();
